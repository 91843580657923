.flex {
  display: flex;
}
.iflex {
  display: inline-flex;
}
.children-grow > * {
  flex-grow: 1;
}
.children-even > * {
  flex-grow: 1;
  flex-basis: 0;
}
.flex-grow {
  flex-grow: 1;
}
.fg0 {
  flex-grow: 0;
}
.fg1 {
  flex-grow: 1;
}
.fg2 {
  flex-grow: 2;
}
.fg3 {
  flex-grow: 3;
}
.fb100 {
  flex-basis: 100%;
}
.fb0 {
  flex-basis: 0;
}
.fdr {
  flex-direction: row;
}
.fdrr {
  flex-direction: row-reverse;
}
.fdc {
  flex-direction: column;
}
.fdcr {
  flex-direction: column-reverse;
}
.fwn {
  flex-wrap: nowrap;
}
.fww {
  flex-wrap: wrap;
}
.fwr {
  flex-wrap: wrap-reverse;
}
.jcc {
  justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.jcsa {
  justify-content: space-around;
}
.jcfs {
  justify-content: flex-start;
}
.jcfe {
  justify-content: flex-end;
}
.aic {
  align-items: center;
}
.aifs {
  align-items: flex-start;
}
.aife {
  align-items: flex-end;
}
.ais {
  align-items: stretch;
}
.aib {
  align-items: baseline;
}
.acc {
  align-content: center;
}
.acfs {
  align-content: flex-start;
}
.acfe {
  align-content: flex-end;
}
.acs {
  align-content: stretch;
}
.acb {
  align-content: baseline;
}
.asc {
  align-self: center;
}
.asfs {
  align-self: flex-start;
}
.asfe {
  align-self: flex-end;
}
.ass {
  align-self: stretch;
}
.asb {
  align-self: baseline;
}
.mla.mla.mla {
  margin-left: auto;
}
.mra.mra.mra {
  margin-right: auto;
}
.tal {
  text-align: left;
}
.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
@media (max-width: 768px) {
  .hide--tablet {
    display: none;
  }
  .wrap--contents {
    display: block;
  }
}
@media (min-width: 769px) {
  .show--tablet {
    display: none;
  }
  .unwrap--contents {
    display: flex;
  }
}
@media (max-width: 785px) {
  .hide--screen-sm {
    display: none;
  }
}
@media (min-width: 786px) {
  .show--screen-sm {
    display: none;
  }
}
.c-image-cropper__wrapper {
  display: flex;
  flex-direction: column;
}
.c-image-cropper__body {
  flex-shrink: 1;
  min-height: 0;
  flex-grow: 1;
}
.cropper-container {
  overflow: hidden;
}
.cropper-container .cropper-point {
  height: 15px;
  width: 15px;
}
.cropper-container .cropper-point:after {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
}
.cropper-container .cropper-point.point-e {
  margin-top: -7.5px;
  right: 0;
}
.cropper-container .cropper-point.point-n {
  margin-left: -7.5px;
  top: 0;
}
.cropper-container .cropper-point.point-w {
  left: 0;
  margin-top: -7.5px;
}
.cropper-container .cropper-point.point-s {
  bottom: 0;
  margin-left: -7.5px;
}
.cropper-container .cropper-point.point-ne {
  right: 0;
  top: 0;
}
.cropper-container .cropper-point.point-nw {
  left: 0;
  top: 0;
}
.cropper-container .cropper-point.point-sw {
  bottom: 0;
  left: 0;
}
.cropper-container .cropper-point.point-se {
  bottom: 0;
  right: 0;
}
.gl-crop-preview {
  display: inline-block;
}
.gl-crop-preview__wrapper {
  background-color: #eee;
}
.gl-img-element {
  max-width: 100%;
  max-height: calc(100vh - 75px);
}
.cropper-modal {
  background-color: #fff;
}
.cropper-view-box {
  background-color: #fff;
}
.cropper-point {
  background-color: green;
}
.cropper-line {
  background-color: green;
}
.cropper-view-box {
  outline-color: green;
}
.warning-crop .cropper-point {
  background-color: orange;
}
.warning-crop .cropper-line {
  background-color: orange;
}
.warning-crop .cropper-view-box {
  outline-color: orange;
}
.invalid-crop .cropper-point {
  background-color: red;
}
.invalid-crop .cropper-line {
  background-color: red;
}
.invalid-crop .cropper-view-box {
  outline-color: red;
}
.upload-reject-message {
  display: none;
}
.dragover-err .upload-reject-message {
  display: block;
}
