.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app__main {
  flex-grow: 1;
}

.html .body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

._card:hover {
  transform: scale(1.01);
  transition: all 0.3s;
}

.repeat-icon:hover {
  color: #a3a3a3;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.css-test * {
  border: 1px solid red !important;
}
@media only screen and (prefers-reduced-motion) {
  ._socialCardGrid:has(> :hover)
    ._card:not(:hover, :has(+ :hover), ._card:hover + *) {
    opacity: 1;
    filter: grayscale(0);
    transition: none;
  }
}

._socialConnectionErrorsIcons :nth-child(2) {
  transform: translateX(-6px);
  mix-blend-mode: multiply;
}

/* ._tableContainer tbody tr:first-child {
  box-shadow: 0 -1px 0 #f0f0f0;
} */
