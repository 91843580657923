.c-image-library__grid {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  margin: 0;
  align-items: start;
}
.c-image-library__grid-item {
  overflow: hidden;
  background: #eee;
  margin: 5px;
  padding: 0;
  display: block;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}
.c-image-library__grid-button {
  overflow: hidden;
  margin: 5px;
  padding: 0;
  display: block;
  border-radius: 5px;
  border: 1px dashed silver;
  cursor: pointer;
}
