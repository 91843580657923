.c-view-section {
  max-width: 992px;
  margin: auto;
}
.c-view-section--fluid {
  max-width: 100%;
}
.c-view-section--narrow {
  max-width: 450px;
}
