/*
    Colour Palette
 */
.c-nav.c-nav.c-nav.c-nav {
  background-color: #475771;
}
.c-nav__item.c-nav__item.c-nav__item.c-nav__item.c-nav__item {
  color: white;
  align-self: stretch;
  border-bottom-width: 5px;
  margin-bottom: -5px;
}
.c-nav__bottom-bar {
  background-image: linear-gradient(270deg, #206BEE 0%, #4C8AF8 100%);
  width: 100%;
  height: 5.07px;
}
/* Unfortunate chain of `.--hide-inactive__child`s to beat menu item's specificity */
.--hide-inactive .--hide-inactive__child.--hide-inactive__child.--hide-inactive__child.--hide-inactive__child:not(.active) {
  display: none;
}
