.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  padding: 5px;
}
.toast {
  margin-top: 0;
  pointer-events: auto;
  margin-bottom: 10px;
}
.toast__message {
  max-width: calc(100vw - 10px);
  width: 30em;
  word-break: break-all;
}
