#okta-sign-in.auth-container.main-container {
  background-color: white;
}

#okta-sign-in.auth-container.main-container {
  border: none;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border: none;
  display: none;
}

#okta-sign-in.auth-container.main-container {
  margin: auto;
}

#okta-sign-in .auth-content {
  padding: 0;
}

#okta-sign-in.no-beacon .auth-content {
  padding: 0;
}

#okta-sign-in .auth-divider {
  margin-top: 25px;
}

#okta-sign-in .o-form-button-bar {
  padding-bottom: 0;
}

#okta-sign-in .o-form-button-bar .button {
  height: auto;
  cursor: pointer;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  color: white;
  background-color: #206BEE;
  border-radius: 4px;
  border: none;
  box-shadow: inset 0 0 0 2px transparent, inset 0 0 0 0 #206bee;
  margin: 0em 0.25em 0em 0em;
  padding: 1em 3em;
}
