/*
    Colour Palette
 */
.c-file-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #F5F6F9;
  flex-grow: 1;
  border: 1px solid #D7DDE6;
}
.c-file-selector > * {
  pointer-events: none;
}
.c-file-selector--drag-over {
  border: 2px solid #206BEE;
}
